/*export let Config = {
    url : 'http://srv.pariver.com:8066/api/v1/app',
    reg : 'http://srv.pariver.com:8066/api/v1/regNew',
    sendData: 'http://srv.pariver.com:8066/api/v1/sendData',
    url_get: 'http://srv.pariver.com:8066/api/v1/',
    url_web: 'http://srv.pariver.com:8066/'
};

export let Config = {
    url : 'http://eparks.feterand.local/api/v1/app',
    reg : 'http://eparks.feterand.local/api/v1/regNew',
    sendData: 'http://eparks.feterand.local/api/v1/sendData',
    url_get: 'http://eparks.feterand.local/api/v1/',
    url_web: 'http://epark.feterand.local/'
};*/
export let Config = {
    url: 'https://myentry.pariver.com:8081/api/v1/app',
    reg: 'https://myentry.pariver.com:8081/api/v1/regNew',
    sendData: 'https://myentry.pariver.com:8081/api/v1/sendData',
    url_get: 'https://myentry.pariver.com:8081/api/v1/',
    url_web: 'https://myentry.pariver.com:8081'
};
export let Data = {
    email: '0',
    code: '0'
};
export let propietario = 7;
