import { Component, OnInit,Input} from '@angular/core';
import { NavParams } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import {Router} from '@angular/router';
import {Storage} from '@ionic/storage';

@Component({
  selector: 'app-modal-mis-reservas',
  templateUrl: './modal-mis-reservas.page.html',
  styleUrls: ['./modal-mis-reservas.page.scss'],
})
export class ModalMisReservasPage implements OnInit {
  public navParams: NavParams;
  @Input() reserva: any;
  
  constructor(
    public modalCtrl: ModalController,
    private router: Router,
    public storage: Storage
  ) { }

  ngOnInit() {

  }

  dismiss() {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }
  gotoBack(){
    this.dismiss();
  }

}
