import { Component, OnInit } from '@angular/core';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { ConsultaService } from 'src/app/services/consulta.service';
import { Usuarios } from 'src/app/models/usuarios';
import { Router } from '@angular/router';
import { propietario } from '../../services/config';
import { Md5 } from 'ts-md5/dist/md5';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-registrarse',
  templateUrl: './registrarse.page.html',
  styleUrls: ['./registrarse.page.scss'],
  providers: [Camera]
})
export class RegistrarsePage implements OnInit {

  public step2: boolean;
  public marcas: Array<string>;
  public usuario: Usuarios;
  public confirmarEmail: string;
  public confirmarPassword: string;

  public bntSiguiente: boolean;
  public CTLazo: string;

  constructor(
    public _camera: Camera,
    private _router: Router,
    private _consulta: ConsultaService,
    public alertController: AlertController,
    private _translate: TranslateService,
  ) {
    this.step2 = false;   //CAMBIAR!!!
    this.usuario = new Usuarios();
    this.usuario.legalCondiciones = false;
    // this.usuario.legalTerminos = false;
  }

  ngOnInit() {
  }

  async presentAlert() {
    let cabecera = "";
    let  mensaje = "";
    this._translate.get('Alerts.campos').subscribe((res: string) => {
      mensaje = res;
      });
    this._translate.get('Alerts.cabezera').subscribe((res: string) => {
      cabecera = res;
      });
    const alert = await this.alertController.create({
      header: '¡Atención!',
      message: mensaje,
      buttons: ['OK']
    });
    await alert.present();
  }

  async alertTerminosYCondiciones() {
    let cabecera = "";
    let  mensaje = "";
    this._translate.get('Alerts.terminos').subscribe((res: string) => {
      mensaje = res;
      });
    this._translate.get('Alerts.cabezera').subscribe((res: string) => {
      cabecera = res;
      });
    const alert = await this.alertController.create({
      header: cabecera,
      message: mensaje,
      buttons: ['OK']
    });
    await alert.present();
  }
  

  async alertEmail() {
    let cabecera = "";
    let  mensaje = "";
    this._translate.get('Alerts.emails').subscribe((res: string) => {
      mensaje = res;
      });
    this._translate.get('Alerts.cabezera').subscribe((res: string) => {
      cabecera = res;
      });
    const alert = await this.alertController.create({
      header: cabecera,
      message: mensaje,
      buttons: ['OK']
    });
    await alert.present();
  }

  async alertPass() {
    let cabecera = "";
    let  mensaje = "";
    this._translate.get('Alerts.pass').subscribe((res: string) => {
      mensaje = res;
      });
    this._translate.get('Alerts.cabezera').subscribe((res: string) => {
      cabecera = res;
      });
    const alert = await this.alertController.create({
      header: cabecera,
      message: mensaje,
      buttons: ['OK']
    });
    await alert.present();
  }

  async alertEmailNoValido() {
    let cabecera = "";
    let  mensaje = "";
    this._translate.get('Alerts.email_invalido').subscribe((res: string) => {
      mensaje = res;
      });
    this._translate.get('Alerts.cabezera').subscribe((res: string) => {
      cabecera = res;
      });
    const alert = await this.alertController.create({
      header: cabecera,
      message: mensaje,
      buttons: ['OK']
    });
    await alert.present();
  }

  async alertExitoRegistro() {
    let cabecera = "";
    let  mensaje = "";
    this._translate.get('Alerts.reistro').subscribe((res: string) => {
      mensaje = res;
      });
    this._translate.get('Alerts.cabezera_bien').subscribe((res: string) => {
      cabecera = res;
      });
    const alert = await this.alertController.create({
      header: cabecera,
      message: mensaje,
      buttons: ['OK']
    });
    await alert.present();
  }

  async alertErrorRegistro() {
    let cabecera = "";
    let  mensaje = "";
    this._translate.get('Alerts.error').subscribe((res: string) => {
      mensaje = res;
      });
    this._translate.get('Alerts.cabezera').subscribe((res: string) => {
      cabecera = res;
      });
    const alert = await this.alertController.create({
      header: cabecera,
      message: mensaje,
      buttons: ['OK']
    });
    await alert.present();
  }
  
  async alertErrorNombre() {
    let  mensaje = "";
    let cabecera = "";
    this._translate.get('Alerts.nombre').subscribe((res: string) => {
      mensaje = res;
      });
    this._translate.get('Alerts.cabezera').subscribe((res: string) => {
      cabecera = res;
      });
    const alert = await this.alertController.create({
      header: cabecera,
      message: mensaje,
      buttons: ['OK']
    });
    await alert.present();
  }

  async alertErrorApellidos() {
    let  mensaje = "";
    let cabecera = "";
    this._translate.get('Alerts.apellidos').subscribe((res: string) => {
      mensaje = res;
      });
    this._translate.get('Alerts.cabezera').subscribe((res: string) => {
      cabecera = res;
      });
    const alert = await this.alertController.create({
      header: cabecera,
      message: mensaje,
      buttons: ['OK']
    });
    await alert.present();
  }

  async alertErrorNIF() {
    let  mensaje = "";
    let cabecera = "";
    this._translate.get('Alerts.dni').subscribe((res: string) => {
      mensaje = res;
      });
    this._translate.get('Alerts.cabezera').subscribe((res: string) => {
      cabecera = res;
      });
    const alert = await this.alertController.create({
      header: cabecera,
      message: mensaje,
      buttons: ['OK']
    });
    await alert.present();
  }

  async alertErrorMovil() {
    let  mensaje = "";
    let cabecera = "";
    this._translate.get('Alerts.movil').subscribe((res: string) => {
      mensaje = res;
      });
    this._translate.get('Alerts.cabezera').subscribe((res: string) => {
      cabecera = res;
      });
    const alert = await this.alertController.create({
      header: cabecera,
      message: mensaje,
      buttons: ['OK']
    });
    await alert.present();
  }

  //VALIDA NIF

  validateDNI(dni) {
    let numero, le, letra;
    let expresion_regular_dni = /^[XYZ]?\d{5,8}[A-Z]$/;

    dni = dni.toUpperCase();

    if (expresion_regular_dni.test(dni) === true) {
      numero = dni.substr(0, dni.length - 1);
      numero = numero.replace('X', 0);
      numero = numero.replace('Y', 1);
      numero = numero.replace('Z', 2);
      le = dni.substr(dni.length - 1, 1);
      numero = numero % 23;
      letra = 'TRWAGMYFPDXBNJZSQVHLCKET';
      letra = letra.substring(numero, numero + 1);
      if (letra != le) {
        //alert('Dni erroneo, la letra del NIF no se corresponde');
        return false;
      } else {
        //alert('Dni correcto');
        return true;
      }
    } else {
      //alert('Dni erroneo, formato no válido');
      return false;
    }
  }

  changeStep(value) {


    if (this.usuario.Nombre == undefined || this.usuario.Nombre == "") {
      this.alertErrorNombre();

    } else if (this.usuario.Apellidos == undefined || this.usuario.Apellidos == "") {
      this.alertErrorApellidos();

//    } else if (!this.validateDNI(this.usuario.NIF)) {
//      this.alertErrorNIF();

    } else if (this.usuario.Movil.toString().length !== 9) {
      this.alertErrorMovil();

    } else if (this.usuario.Email.localeCompare(this.usuario.ConfEmail) != 0) {
      this.alertEmail();

    } else if (this.usuario.Email.includes("@") == false || this.usuario.Email.includes(".") == false) {
      this.alertEmailNoValido();

    } else if (this.step2 == true) {
      this.step2 = value;
    }
    else if (this.usuario.Nombre && this.usuario.Apellidos && this.usuario.Movil && this.usuario.NIF && this.usuario.Email) {
      this.step2 = value;
    }
    else {
      this.presentAlert();
    }
  }
  postUser() {

    let myCode = Md5.hashStr(this.usuario.Password);

    let x = '{"clave" : "REGISTRO", "name" : "' + this.usuario.Nombre +
        '", "surname" : "' + this.usuario.Apellidos +
        '", "email" : "' + this.usuario.Email +
        '", "telefono" : "' + this.usuario.Movil +
        '", "nif" : "' + this.usuario.NIF +
        '", "password" : "' + myCode +
        '", "id" : "' + propietario +
        '" }';


    this._consulta.registro(x).subscribe(
      (data) => {

        if (data['acceso'] == "No" && data['id'] == null) {
          this.alertEmailNoValido();
        } else {
          this._consulta.usuario.Id = data['id'];
          this.alertExitoRegistro();
          this._router.navigate(['/principal']);
        }
        // console.log("Te has registrado " + this._consulta.usuario.Id)
        // this._consulta.usuario.Nombre = data['nombre'];
      },
      error => {
        console.log(<any>error);
        this.alertErrorRegistro();
      }
    );
  }

  submitBasic() {

    if (this.usuario.Password) {
      if (this.usuario.legalCondiciones == false) {
        this.alertTerminosYCondiciones();
      } else if (this.usuario.Password.localeCompare(this.usuario.ConfPass) != 0) {
        this.alertPass();
      } else {
        this.postUser();
      }
    }

    else {
      this.presentAlert();
    }

    //this.postUser();

  }
  validateCheckboxLC(value) {
    this.usuario.legalCondiciones = value;
    console.log(this.usuario.legalCondiciones);
  }

  terminos(value) {
    this._consulta.legal = value;
    console.log(value);
    this._router.navigate(['/aviso-legal']);
  }

}
