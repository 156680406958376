import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { HttpModule } from '@angular/http';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx'
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { PrincipalPageModule } from './pages/principal/principal.module';
import { AparcarPageModule } from './pages/aparcar/aparcar.module';
import { AccesoPageModule } from './pages/acceso/acceso.module';
import { AccederPageModule } from './pages/acceder/acceder.module';
import { RetirarPageModule } from './pages/retirar/retirar.module';
import { ConsultaService } from './services/consulta.service';
import { RegistrarsePageModule } from './pages/registrarse/registrarse.module';
import { MetodosPagoPageModule } from './pages/metodos-pago/metodos-pago.module';
import { ChangePassPageModule } from './pages/modals/change-pass/change-pass.module';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { NavController } from '@ionic/angular'
import { IonicStorageModule } from '@ionic/storage';
import { ElegirTarjetaLazoPageModule } from './pages/elegir-tarjeta-lazo/elegir-tarjeta-lazo.module';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {AndroidPermissions} from '@ionic-native/android-permissions/ngx';
import {ModalMisReservasPage} from './pages/modals/modal-mis-reservas/modal-mis-reservas.page';
import { ModalMisReservasPageModule } from './pages/modals/modal-mis-reservas/modal-mis-reservas.module';
import { ModalVideoPageModule } from './pages/modals/modal-video/modal-video.module';
import { StreamingMedia, StreamingVideoOptions, StreamingAudioOptions } from '@ionic-native/streaming-media/ngx';



@NgModule({
  declarations: [
    AppComponent,

  ],
  entryComponents: [
    // AparcarPage,
    // PrincipalPage

  ],
  imports: [
    BrowserModule, 
    BrowserAnimationsModule,
    HttpClientModule,

    HttpModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    AparcarPageModule,
    PrincipalPageModule,
    ModalMisReservasPageModule,
    ModalVideoPageModule,
    AccesoPageModule,
    AccederPageModule,
    RetirarPageModule,
    RegistrarsePageModule,
    MetodosPagoPageModule,
    ChangePassPageModule,
    ElegirTarjetaLazoPageModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  })
  ],
  providers: [
    Geolocation,
    // NativeStorage,
    StatusBar,
    SplashScreen,
    AndroidPermissions,
    ConsultaService,
    NavController,
    NativeStorage,
    StreamingMedia,


    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

export  function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

