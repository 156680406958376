import {Component, OnInit, ViewEncapsulation } from '@angular/core';
import {ActionSheetController, AlertController, ModalController} from '@ionic/angular';
import {ConsultaService} from '../../services/consulta.service';
import {Map, latLng, tileLayer, Layer, marker, icon, popup} from 'leaflet';
import { Instalacion } from '../../models/instalacion';
import { propietario } from '../../services/config';
import { TranslateService } from '@ngx-translate/core';
import {Storage} from '@ionic/storage';
import {ModalTarifasPage} from '../modals/modal-tarifas/modal-tarifas.page';
import { Router, ActivatedRoute } from '@angular/router';
import {map, filter} from 'rxjs/operators';
@Component({
    selector: 'app-principal',
    templateUrl: './principal.page.html',
    styleUrls: ['./principal.page.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PrincipalPage implements OnInit {

  public inicio: boolean;
  public map: Map;
  public marker: marker;
  public instalaciones: any = [];
  public title: string;
  public opciones: any = [];
  public tarifas: any = [];
  public data: any;
  private plazas: any;
    constructor(
        public _consulta: ConsultaService,
        public alertController: AlertController,
        public _router: Router,
        private actionSheet: ActionSheetController,
        private _translate: TranslateService,
        private storage: Storage,
        private _modalController: ModalController,
        private _actrouter: ActivatedRoute,
    ) {
        this.inicio = false;
       
    }

    ngOnInit() {
        this. getInstalaciones();
        this._actrouter.paramMap.pipe(map(() => window.history.state)).subscribe(
            result => {
                if (typeof result['data'] !== 'undefined') {
                    this.data = result['data'];
                }
            },
            error => {
                ///
            });
        this._actrouter.paramMap.pipe(map(() => window.history.state)).subscribe(
            result => {
                if (typeof result['plazas'] !== 'undefined') {
                    this.plazas = result['plazas'];
                }
            },
            error => {
                    ///
            });
    
    }
    ionViewWillEnter() {
        this.storage.get('last_buy').then( last => {
            if (last != null) {
                const cons = '{"id": "' + last +  '"}';
                this._consulta.isPayed(cons).subscribe(result => {
                    if (result == 1) {
                        this.alertError('Reserva', 'Su reserva ha sido realizada correctamente', 1);
                    } else {
                        this.alertError('Error en el pago', 'Ha habido un problema con su pago . Revise sus reservas ', 0);
                    }
                });
                this.storage.set('last_buy', null);
            }
        });
    }

    changeInicio(value) {
        this.inicio = value;
    }

    getInstalaciones(){

        this._consulta.getInstalaciones().subscribe(
            result => {
                console.log(result);
               this.instalaciones = result;
            },
            error => {
                console.log(error);
            });
    }

    goReservas(instalacion: Instalacion ) {
        this._router.navigateByUrl('/acceso', { state: { instalacion: instalacion,  data : this.data, plazas: this.plazas } });
       // if (this._consulta.usuario.Id != undefined && typeof this._consulta.usuario.Id === 'string'){
           /* if (parseInt(instalacion.tramohorario) >= 1440) {
                this._router.navigateByUrl('/acceso', { state: { instalacion: instalacion } });
            } else {
                this._router.navigateByUrl('/acceso', { state: { instalacion: instalacion } });
            }*/
       /* } else {
            this._router.navigateByUrl('/acceder');
        }*/
    }

    goAyuda() {
        this._router.navigate(['/ayuda']);
    }

    showTarifa(instalacion: Instalacion ) {
        let text = '';
        const cons = '{"id": "' + this._consulta.usuario.Id + '","id_instalacion":"' + instalacion.id + '" ,"language": "ES"}';
        this._consulta.getOpciones(cons).subscribe(
            result => {
                console.log(result);
                this.tarifas = result['Total'];
                this.presentModal(instalacion);
            });

    }
    async presentModal(instalacion) {
        const modal = await this._modalController.create({
            component: ModalTarifasPage,
            componentProps: {
                'tarifas': this.tarifas,
                'instalacion': instalacion
            }
        });
        return await modal.present();
    }
    async alertError(hdr: string, msg: string, ok: number) {
        const alert = await this.alertController.create({
            header: hdr,
            message: msg,
            backdropDismiss: false,
            buttons: [{
                text: 'OK',
                handler: () => {
                    if (ok == 1) {
                        this._router.navigate(['/mis-reservas']);
                    }
                }
            }]
        });
        await alert.present();
    }



}
