import {Component, OnInit} from '@angular/core';
import {Platform, ActionSheetController, NavController, IonRouterOutlet} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {MenuController} from '@ionic/angular';
import {ConsultaService} from './services/consulta.service';
import {Router, ActivatedRoute} from '@angular/router';
import {Data} from './services/config';
import { TranslateService } from '@ngx-translate/core';
import { ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import {AndroidPermissions} from '@ionic-native/android-permissions/ngx';



@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    public _email: string;

    @ViewChild(IonRouterOutlet) routerOutlet: IonRouterOutlet;
    constructor(
        public platform: Platform,
        private splashScreen: SplashScreen,
        private actionSheetCtrl: ActionSheetController,
        private statusBar: StatusBar,
        private menu: MenuController,
        public _consulta: ConsultaService,
        private _router: Router,
        private nav: NavController,
        private _translate: TranslateService,
        private _actrouter: ActivatedRoute,
        private location: Location,
        public androidPermissions: AndroidPermissions
    ) {
        this._email = Data.email;
        this.initializeApp();
        this.backButtonMenuEvent();
        this._initTranslate();
        this.platform.backButton.subscribe(() => {
            this.location.back();
          });
    }

    ngOnInit() {
        this.androidPermissions.requestPermissions([
            'WRITE_EXTERNAL_STORAGE',
            'READ_EXTERNAL_STORAGE']);

        if (this.platform.is('android')) {
            this.statusBar.backgroundColorByHexString('#fff');
        }
        this._initTranslate();
    }

    private _initTranslate()
    {
       // Set the default language for translation strings, and the current language.
       this._translate.setDefaultLang('en'); 
       if (this._translate.getBrowserLang() !== undefined)
       {
           this._translate.use(this._translate.getBrowserLang());
       }
       else
       {
           this._translate.use('en'); // Set your language here
       }
    }


    backButtonMenuEvent() {

        if (this.menu.isOpen()) {
            this.platform.backButton.subscribeWithPriority(9999, () => {
                // this.menu.close();
                try {
                    const element = this.menu.getOpen();
                    if (element !== null) {
                        this.menu.close();
                        // return true;
                    }

                } catch (e) {

                }
            });
        } else {
            this.nav.back();
        }

    }

    cierraMenu() {
        this.menu.close();
    }

    openFirst() {
        this.menu.enable(true, 'first');
        this.menu.open('first');
    }

    openEnd() {
        this.menu.open('end');
    }

    openCustom() {
        this.menu.enable(true, 'custom');
        this.menu.open('custom');
    }

    initializeApp() {
        this.platform.ready().then(() => {
            this.statusBar.styleDefault();
            this.splashScreen.hide();
        });
    }

    cerrarSesion() {
        this._consulta.cerrarSesion();
        this._router.navigate(['/acceder']);
        this.cierraMenu();
    }

    avisoLegal() {
        this._router.navigate(['/aviso-legal']);
        this.cierraMenu();
    }
    moreInfo(){
        this._router.navigate(['/ayuda']);
        this.cierraMenu();
        //window.open('https://bici.unizar.es/aparcabicis', '_blank');
    }

}
