import { Component, OnInit, Input } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import {Tarifa} from '../../../models/reserva';
import {Router} from '@angular/router';
import {Storage} from '@ionic/storage';
import {ConsultaService} from '../../../services/consulta.service';

@Component({
  selector: 'app-modal-tarifas',
  templateUrl: './modal-tarifas.page.html',
  styleUrls: ['./modal-tarifas.page.scss'],
})
export class ModalTarifasPage implements OnInit {

  public navParams: NavParams;
  @Input() tarifas: any = [];
  @Input() instalacion: any;

  constructor(
      private consulta: ConsultaService,
      public modalCtrl: ModalController,
      private router: Router,
      public storage: Storage
  ) { }

  ngOnInit() {

  }

  dismiss() {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }
  gotoBack(){
    this.dismiss();
  }
  reserva(){
    // this.router.navigateByUrl('/reserva-dias', { state: { instalacion: this.instalacion } });
    this.dismiss();
  }
}
