import {MisReservasPage} from './../mis-reservas/mis-reservas.page';
import {Component, OnInit} from '@angular/core';
import {ConsultaService} from '../../services/consulta.service';
import {Router, ActivatedRoute} from '@angular/router';
import {map, filter} from 'rxjs/operators';
import {AlertController, IonDatetime, LoadingController} from '@ionic/angular';
import {Reservas} from '../../models/reservas';
import {Instalacion} from '../../models/instalacion';
import {Geolocation, Geoposition} from '@ionic-native/geolocation/ngx';
import {isDirectiveInstance} from '@angular/core/src/render3/context_discovery';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';

@Component({
    selector: 'app-acceso',
    templateUrl: './acceso.page.html',
    styleUrls: ['./acceso.page.scss'],
})
export class AccesoPage implements OnInit {

    public codigo: string;
    public codigoActivo: boolean;
    public instalacion: any;
    public tarifas: any = [];
    public plazas: any = 0;
    private loading;
    public disabled: boolean;
    public avisoDejar: boolean = false;
    public avisoCoger: boolean = false;
    public avisoLejos: boolean = true;
    public lat: number;
    public lon: number;
    public data: any;

    constructor(
        public _consulta: ConsultaService,
        private _router: Router,
        private _actrouter: ActivatedRoute,
        private alertController: AlertController,
        public loadingController: LoadingController,
        public geolocation: Geolocation
    ) {
        this.codigoActivo = true;
        this.disabled = true;


    }

    ngOnInit() {
        //this.presentLoading('Analizando posición');

        this.inicializeObjects();
        this.getGeolocation();

    }

    getGeolocation() {
        this.geolocation.getCurrentPosition().then((geoposition: Geoposition) => {

            this.lat = geoposition.coords.latitude;
            this.lon = geoposition.coords.longitude;
            let metros = this.measure(this.instalacion.latitud, this.instalacion.longitud, this.lat, this.lon);
            console.log(metros);

            if (this.measure(this.instalacion.latitud, this.instalacion.longitud, this.lat, this.lon) > 150) {
                this.disabled = true;
                this.avisoLejos = true;
            } else {
                this.disabled = false;
                this.avisoLejos = false;
            }

            if (this.avisoLejos == true) {
                this.presentLoadingDistance('Te encuentras demasiado lejos de la instalación. Cuando estés junto a la instalación, presiona el botón "Repetir Búsqueda" para poder acceder. Estás a: ' + metros + ' m.');
                setTimeout(this.setStatus.bind(this), 5000, 'Scan complete');
            } else {

            }

        }).catch((error) => {
            this.presentLoading('Error al acceder a la localización por favor revise los permisos.');
            setTimeout(this.setStatus.bind(this), 5000, '');
            this.avisoLejos = true;
        });


    }

    measure(lat1, lon1, lat2, lon2) {  // generally used geo measurement function
        let p = 0.017453292519943295;
        let c = Math.cos;
        let a = 0.5 - c((lat1 - lat2) * p) / 2 + c(lat2 * p) * c((lat1) * p) * (1 - c(((lon1 - lon2) * p))) / 2;
        let dis = (12742 * Math.asin(Math.sqrt(a)));
        return Math.trunc(dis * 1000);
    }

    inicializeObjects() {
        this._actrouter.paramMap.pipe(map(() => window.history.state)).subscribe(
            result => {
                if (typeof result['data'] !== 'undefined') {
                    this.data = result['data'];
                }
            },
            error => {
                ///
            });

        this._actrouter.paramMap.pipe(map(() => window.history.state)).subscribe(
            result => {
                if (typeof result['instalacion'] !== 'undefined') {
                    this.instalacion = result['instalacion'];
                }
            },
            error => {
                ///
            });

        this._actrouter.paramMap.pipe(map(() => window.history.state)).subscribe(
            result => {
                if (typeof result['plazas'] !== 'undefined') {
                    this.plazas = result['plazas'].result;
                }
            },
            error => {
                ///
            });

    }

    getInstalaciones() {

        this._consulta.getInstalaciones().subscribe(
            result => {
                console.log(result);
                this.instalacion = result;
            },
            error => {
                console.log(error);
            });
    }


    async alertErrorSeleccionarTarjetasLazo() {
        const alert = await this.alertController.create({
            header: '¡Atención!',
            message: 'No tienes Tarjetas Lazo registradas.',
            buttons: ['OK']
        });
        await alert.present();
    }


    anual() {
        this._router.navigateByUrl('/reserva-mes', {state: {instalacion: this.instalacion, importe: this.tarifas[3].importe}});
    }

    finde() {
        this._router.navigateByUrl('/reserva-finde', {state: {instalacion: this.instalacion, importe: this.tarifas[2].importe}});
    }

    rdias() {
        this._router.navigateByUrl('/reserva-dias', {state: {instalacion: this.instalacion, importe: this.tarifas[1].importe}});
    }

    dia() {
        this._router.navigateByUrl('/reserva-horas', {state: {instalacion: this.instalacion, importe: this.tarifas[0].importe}});
    }

    async presentLoading(hdr: string) {
        // Prepare a loading controller
        this.loading = await this.loadingController.create({
            message: hdr,
            spinner: null
        });
        // Present the loading controller
        await this.loading.present();
    }

    async presentLoadingDistance(hdr: string) {
        // Prepare a loading controller
        this.loading = await this.loadingController.create({
            message: hdr,
            spinner: null
        });
        // Present the loading controller
        await this.loading.present();
    }

    setStatus(message) {
        this.loading.dismiss();
        //this.disabled = false;
    }

    open(id, accion) {
        this.geolocation.getCurrentPosition().then((geoposition: Geoposition) => {
            this.lat = geoposition.coords.latitude;
            this.lon = geoposition.coords.longitude;
            let metros = this.measure(this.instalacion.latitud, this.instalacion.longitud, this.lat, this.lon);
            if (metros > 150) {
                this.disabled = true;
                this.avisoLejos = true;
            } else {
                this.disabled = false;
                this.avisoLejos = false;
            }
            if (this.avisoLejos == true) {
                this.presentLoadingDistance('Te encuentras demasiado lejos de la instalación. Cuando estés junto a la instalación, presiona el botón "Repetir Búsqueda" para poder acceder. Estás a: ' + metros + ' m.');
                setTimeout(this.setStatus.bind(this), 5000, 'Scan complete');
            } else {
                this.presentLoading('Puerta abierta. Puedes acceder');
                setTimeout(this.setStatus.bind(this), 5000, '');

                const cons = '{"clave" : "OPUN", "id" : "' + id + '","usuario" : "' + this.data.nia + '" ,"accion" : "' + accion + '" }';
                this._consulta.open(cons).subscribe(result => {
                    this.avisoCoger = true;
                    this.plazas = result['result'];
                });
            }
        }).catch((error) => {
            this.presentLoading('Error al acceder a la localización por favor revise los permisos.');
            setTimeout(this.setStatus.bind(this), 5000, '');
            this.avisoLejos = true;
        });


    }

    repetirBusqueda() {
        this.inicializeObjects();
        this.getGeolocation();
    }

    goins() {
        this._router.navigate(['/principal']);
    }

}
