import { Injectable } from '@angular/core';
import { Http} from '@angular/http';
import { map } from 'rxjs/operators';

import { HttpClient, HttpHeaders, HttpHeaderResponse } from '@angular/common/http';
import { Usuarios } from '../models/usuarios';
import {Config, propietario} from './config';
import { Data } from './config';
import { TarjetasLazo } from '../models/tarjetasLazo';
import { Storage } from '@ionic/storage';
import { Login } from '../models/login';
import { Router } from '@angular/router';
import { Reservas } from '../models/reservas';


// import { AppPreferences } from '@ionic-native/app-preferences/ngx';


const STORAGE_KEY = 'myStorage';

@Injectable()
export class ConsultaService {
    public url: string;
    public url_get: string;
    public reg: string;
    public sendData: string;
    public myLogin : Login;

    public misReservas: Reservas[];
    private _headers = new HttpHeaders();
    private _token = new HttpHeaders();
    ajustes = {
        email : "",
        code : ""
    }

    public totalPlazas: string;

    public usuario: Usuarios;
    public tarjetaLazo: TarjetasLazo;

    public legal: boolean;
    // public id : string;

    public _email: string;
    public _code: string;

    constructor(
        public _http: HttpClient,
        public _http2: Http,
        public storage: Storage,
        public _router: Router,
    ) {
        this.url = Config.url;
        this.url_get = Config.url_get;
        this.reg = Config.reg;
        this.sendData = Config.sendData;
        this._email = Data.email;
        this._code = Data.code;

        this.usuario = new Usuarios();
        this.tarjetaLazo = null;
        this.legal = false;
        this.misReservas=[];
        this._headers.append('Access-Control-Allow-Origin', '*');
        this._headers.append('Accept', 'application/json');
        this._headers.append('content-type', 'application/json');
        this._headers.append('Content-Type', 'multipart/form-data');
    }
    getInstalaciones() {
        const payload = '{"id" : "' + propietario +
            '"}';
        console.log(payload);
        return this._http.post(this.url_get + 'ins', payload,{
            headers: this._headers,
            responseType: 'json'
        });
    }
    getUser(token) {
        const payload = '{"token" : "' + token +'"}';
        return this._http.post(this.url_get + 'biciunizar', payload, {
            headers: this._headers,
            responseType: 'json'
        });
    }

    getPlazasLibres(payload: any) {
        return this._http.post(this.url_get + 'getFree', payload, {
            headers: this._headers,
            responseType: 'json'
        });
    }
    getDiasLibres(payload: any) {
        return this._http.post(this.url_get + 'getDateFree', payload, {
            headers: this._headers,
            responseType: 'json'
        });
    }
    getMatriculas(payload: any) {
        return this._http.post(this.url_get + 'token', payload, {
            headers: this._headers,
            responseType: 'json'
        });
    }
    setToken(payload: any) {
        return this._http.post(this.url_get + 'setToken', payload, {
            headers: this._headers,
            responseType: 'json'
        });
    }

    getBooking(payload: any) {
        return this._http.post(this.url_get + 'getBooking', payload, {
            headers: this._headers,
            responseType: 'json'
        });
    }


    getPistasLibres(payload: any) {
        return this._http.post(this.url_get + 'getFreeOneDay', payload, {
            headers: this._headers,
            responseType: 'json'
        });
    }

    getHorasLibres(payload: any) {
        return this._http.post(this.url_get + 'getFreeHour', payload, {
            headers: this._headers,
            responseType: 'json'
        });
    }
    getToken(payload: any) {
        return this._http.post(this.url_get + 'getFreeHour', payload, {
            headers: this._headers,
            responseType: 'json'
        });

    }

    getOpciones(payload: any){
        return this._http.post(this.url_get + 'huescagetOpt', payload, {
            headers: this._headers,
            responseType: 'json'
        });
    }
    getCameras(payload: any){
        return this._http.post(this.url_get + 'getFreeCam', payload, {
            headers: this._headers,
            responseType: 'json'
        });
    }

    compruebaLogin(page) {
        this.storage.get('email').then( user => {
           this.storage.get('password').then( pass => {
               const payload = '{"clave" : "LOGIN", "email" : "' + user + '","code" : "' + pass +  '","id" : "' + propietario + '"}';
               this.postPlaza(payload).subscribe(
                   (data) => {
                       this.usuario.Id = data['id'];
                       this.usuario.Nombre = data['nombre'];
                       this.usuario.Apellidos = data['apellidos'];


                       if (this.usuario.Id == null) {
                           this._router.navigate(['/acceder']);
                       } else {
                            if (page == true){
                                this._router.navigate(['/principal']);
                            }
                       }
                   },
                   error => {

                   }
               );
           });
        });


    }

    postPlaza(payload: any) {
        // let req = <JSON>payload;
        return this._http.post(this.url, payload, {
            headers: this._headers,
            responseType: 'json'
        })
    }

    registro(payload: any) {
        return this._http.post(this.reg, payload, {
            headers: this._headers,
            responseType: 'json'
        })
    }

    envioDatos(payload:any){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin', '*');
        headers.append('Accept', 'application/json');
        headers.append('content-type', 'application/json');
        headers.append('Content-Type', 'multipart/form-data');
        return this._http2.post(this.url, payload).pipe(map(res => res.json()));
    }


    miCuenta(payload: any) {
        return this._http.post(this.sendData, payload, {
            headers: this._headers,
            responseType: 'json'
        })
    }

    sendLazo(payload: any) {
       
        return this._http.post(this.url, payload, {
            headers: this._headers,
            responseType: 'json'
        })
    }

    getTarjetasLazo(payload: any) {
       
        return this._http2.post(this.url, payload).pipe(map(res => res.json()));
    }

    sendPass(payload: any) {
       
        return this._http.post(this.url, payload, {
            headers: this._headers,
            responseType: 'json'
        })
    }

    cerrarSesion() {
        this.usuario = new Usuarios();
        this.storage.clear();
    }

    sendLazoReserva(payload: any) {
       
        return this._http.post(this.url, payload, {
            headers: this._headers,
            responseType: 'json'
        })
    }

    consultoMisReservas(payload: any) {
        
        return this._http2.post(this.url, payload).pipe(map(res => res.json()));
    }
  

    deleteTarjetaLazo(payload: any) {
    
        return this._http2.post(this.url, payload).pipe(map(res => res.json()));
    }

    deleteReserva(payload: any) {

        return this._http2.post(this.url, payload).pipe(map(res => res.json()));
    }

    recPass(payload: any) {
       
        return this._http.post(this.url, payload, {
            headers: this._headers,
            responseType: 'json'
        });
    }

    setReserva(payload: any) {
        return this._http.post(this.url_get + 'reserva', payload, {
            headers: this._headers,
            responseType: 'json'
        });

    }
    payment(payload: any) {
        return this._http.post(this.url_get + 'redS', payload, {
            headers: this._headers,
            responseType: 'json'
        });
    }
    isPayed(payload: any) {
        return this._http.post(this.url_get + 'payment', payload, {
            headers: this._headers,
            responseType: 'json'
        });
    }

    open(payload: any) {
        return this._http.post(this.url, payload, {
            headers: this._headers,
            responseType: 'json'
        });
    }
}
