import {Component, OnInit, OnDestroy} from '@angular/core';
import {ConsultaService} from 'src/app/services/consulta.service';
import {Md5} from 'ts-md5/dist/md5';
import {Login} from '../../models/login';
import {Router} from '@angular/router';
import { propietario } from '../../services/config';
import {AlertController, LoadingController } from '@ionic/angular';
import {Storage} from '@ionic/storage';
import {Data} from '../../services/config';
import {TranslateService} from '@ngx-translate/core';
import {Config} from '@ionic/core';

// import { AppPreferences } from '@ionic-native/app-preferences/ngx';


@Component({
    selector: 'app-acceder',
    templateUrl: './acceder.page.html',
    styleUrls: ['./acceder.page.scss'],
    providers: []
})
export class AccederPage implements OnInit, OnDestroy{

    public login: Login;
    public _email: string;
    public _code: string;
    private loading;
    private plazas:any;
    private urlParameters: Array<any> = [];
    private token: any;
    private user: any;
    private acces:any;

    constructor(
        public _consulta: ConsultaService,
        public _router: Router,
        public alertController: AlertController,
        public _storage: Storage,
        public loadingController: LoadingController,
        // public _appPreferences : AppPreferences
        // private nativeStorage : NativeStorage
    ) {
        this.login = new Login();
        this._email = Data.email;
        this._code = Data.code;
    }

    IonViewDidLoad(){

    }
    ngOnInit() {

        if (document.URL.indexOf("?") > 0) {
            let splitURL = document.URL.split("?");
            let splitParams = splitURL[1].split("=");
            let splitParams2 = splitParams[1].split("&");
            let splitParams3 = splitParams2[0].split("%20");
            if (splitParams [0] == 'my_token'){
                this.token = splitParams3 [0];
                this._consulta.getUser(this.token).subscribe(result => {
                    this.acces = result;
                    this.user = result['nia'];
                    this.presentLoading('Comprobando Usuario');
                    setTimeout(this.setStatus.bind(this), 2000, 'Scan complete');
                });

            }

        }else{
            this.alertErrorLogin('Usuario no válido', 'El usuario introducido no es válido');
            this._router.navigateByUrl('/error-acceso');
        }

    }

    ngOnDestroy() {
        this.login = new Login();
    }

    async alertErrorLogin(hdr: string, msg: string) {
        const alert = await this.alertController.create({
            header: hdr,
            message: msg,
            backdropDismiss: false,
            buttons: [{
                text: 'OK'
            }]
        });

        await alert.present();
    }
    async presentLoading(hdr: string) {
        // Prepare a loading controller
        this.loading = await this.loadingController.create({
            message:  '<div style=\'text-align: center;\'><ion-spinner class=\'spiner-modal\' name=\'bubbles\'></ion-spinner>' + hdr + '</div>',
            spinner: null
        });

        // Present the loading controller
        await this.loading.present();
    }

    setStatus(message) {
        this.loading.dismiss();
        console.log(this.user);
        if (this.user){
            const cons =  '{"clave" : "ACCESBICI", "id" : "' + 7 + '","usuario" : "' + this.acces.nia + '" ,"nombre" : "' + this.acces.dni + '" }';
            this._consulta.open(cons).subscribe( result => {
                this._router.navigateByUrl('/principal',{ state: { data : this.acces} });
            });
        } else {
            this.alertErrorLogin('Usuario no válido', 'El usuario introducido no es válido');
            this._router.navigateByUrl('/error-acceso');
        }

    }


}
