import { NgModule } from '@angular/core';
import { Routes, RouterModule  } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'acceder', pathMatch: 'full' },
  { path: 'principal', loadChildren: './pages/principal/principal.module#PrincipalPageModule' },
  { path: 'aparcar', loadChildren: './pages/aparcar/aparcar.module#AparcarPageModule' },
  { path: 'acceso', loadChildren: './pages/acceso/acceso.module#AccesoPageModule' },
  { path: 'ayuda', loadChildren: './pages/ayuda/ayuda.module#AyudaPageModule' },
  { path: 'acceder', loadChildren: './pages/acceder/acceder.module#AccederPageModule' },
  { path: 'registrarse', loadChildren: './pages/registrarse/registrarse.module#RegistrarsePageModule' },
  { path: 'retirar', loadChildren: './pages/retirar/retirar.module#RetirarPageModule' },
  { path: 'mi-cuenta', loadChildren: './pages/mi-cuenta/mi-cuenta.module#MiCuentaPageModule' },
  { path: 'add-data', loadChildren: './pages/add-data/add-data.module#AddDataPageModule' },
  { path: 'metodos-pago', loadChildren: './pages/metodos-pago/metodos-pago.module#MetodosPagoPageModule' },
  { path: 'change-pass', loadChildren: './pages/modals/change-pass/change-pass.module#ChangePassPageModule' },
  { path: 'mis-reservas', loadChildren: './pages/mis-reservas/mis-reservas.module#MisReservasPageModule' },
  { path: 'elegir-tarjeta-lazo', loadChildren: './pages/elegir-tarjeta-lazo/elegir-tarjeta-lazo.module#ElegirTarjetaLazoPageModule' },
  { path: 'elegir-codigo', loadChildren: './pages/elegir-codigo/elegir-codigo.module#ElegirCodigoPageModule' },
  { path: 'aviso-legal', loadChildren: './pages/aviso-legal/aviso-legal.module#AvisoLegalPageModule' },
  { path: 'contacto', loadChildren: './pages/contacto/contacto.module#ContactoPageModule' },
  { path: 'forgot-password', loadChildren: './pages/forgot-password/forgot-password.module#ForgotPasswordPageModule' },
  { path: 'reserva-dias',  loadChildren: './pages/reserva-dias/reserva-dias.module#ReservaDiasPageModule'},
  { path: 'reserva-finde',  loadChildren: './pages/reserva-finde/reserva-finde.module#ReservaFindePageModule'},
  { path: 'reserva-horas', loadChildren: './pages/reserva-horas/reserva-horas.module#ReservaHorasPageModule' },
  { path: 'reserva-mes', loadChildren: './pages/reserva-mes/reserva-mes.module#ReservaMesPageModule' },
  { path: 'confirmar-reserva', loadChildren: './pages/confirmar-reserva/confirmar-reserva.module#ConfirmarReservaPageModule' },
  { path: 'modal-reserva', loadChildren: './pages/modals/modal-reserva/modal-reserva.module#ModalReservaPageModule' },
  { path: 'modal-mis-reservas', loadChildren: './pages/modals/modal-mis-reservas/modal-mis-reservas.module#ModalMisReservasPageModule' },
  { path: 'payment', loadChildren: './pages/payment/payment.module#PaymentPageModule' },
  { path: 'modal-tarifas', loadChildren: './pages/modals/modal-tarifas/modal-tarifas.module#ModalTarifasPageModule' },
  { path: 'modal-video', loadChildren: './pages/modals/modal-video/modal-video.module#ModalVideoPageModule' },
  { path: 'confirmar-bono', loadChildren: './pages/confirmar-bono/confirmar-bono.module#ConfirmarBonoPageModule' },
  { path: 'reserva-info', loadChildren: './pages/reserva-info/reserva-info.module#ReservaInfoPageModule' },
  { path: 'error-acceso', loadChildren: './pages/error-acceso/error-acceso.module#ErrorAccesoPageModule' },
  { path: '**', redirectTo: 'acceder', pathMatch: 'full' },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
