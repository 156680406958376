export class  Usuarios{
    public Id: string;
    public NIF: string;
    public Nombre: string;
    public Apellidos: string;
    public Movil: number;

    public Email: string;
    public ConfEmail: string;
    public Password: string;
    public ConfPass: string;
    public direccion: string;
    public localidad: string;
    public pais: string;
    public codpost: string;

    public legalTerminos: boolean;
    public legalCondiciones: boolean;

    constructor(){
    }
}
