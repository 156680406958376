import { Component, OnInit, Input } from '@angular/core';
import { NavParams, LoadingController } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import {Router} from '@angular/router';
import {Storage} from '@ionic/storage';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {ConsultaService} from '../../../services/consulta.service';



@Component({
  selector: 'app-modal-video',
  templateUrl: './modal-video.page.html',
  styleUrls: ['./modal-video.page.scss'],
})
export class ModalVideoPage implements OnInit {

  public navParams: NavParams;
  public safeUrl;
  private loading;
  @Input() video: string;
  @Input() nombre: string;

  constructor(
      private consulta: ConsultaService,
      public modalCtrl: ModalController,
      private router: Router,
      public storage: Storage,
      public loadingController: LoadingController,
      private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.video);
    console.log(this.safeUrl);
    this.presentLoading('Cargando Cámara');
    setTimeout(this.dismissLoagind.bind(this), 3000, null);
  }

  dismiss() {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }
  gotoBack(){
    this.dismiss();
  }
  dismissLoagind(){
    this.loading.dismiss();
  }
  reserva() {
    // this.router.navigateByUrl('/reserva-dias', { state: { instalacion: this.instalacion } });
    this.dismiss();
  }
  async presentLoading(hdr: string) {
    // Prepare a loading controller
    this.loading = await this.loadingController.create({
      message: hdr
    });
    // Present the loading controller
    await this.loading.present();

  }
}
