import { Component, OnInit } from '@angular/core';
import { Usuarios } from 'src/app/models/usuarios';
import { TarjetasLazo } from 'src/app/models/tarjetasLazo';
import { ConsultaService } from 'src/app/services/consulta.service';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { Reservas } from 'src/app/models/reservas';

@Component({
  selector: 'app-elegir-tarjeta-lazo',
  templateUrl: './elegir-tarjeta-lazo.page.html',
  styleUrls: ['./elegir-tarjeta-lazo.page.scss'],
})
export class ElegirTarjetaLazoPage implements OnInit {

  public usuario: Usuarios;
  public originalTarjetasLazo: TarjetasLazo[];
  public tarjetasLazo: TarjetasLazo[];
  public vistaTarjetasLazo: TarjetasLazo[];
  public misReservas: Reservas[];
  public allInfoTarjetas: Reservas[];
  public TLazo: string;
  public lazo: boolean;

  public T1number: string;
  public T1alias: string;

  constructor(
    private _consulta: ConsultaService,
    private _router: Router,
    public alertController: AlertController
  ) {
    this.originalTarjetasLazo = [];
    this.tarjetasLazo = null;
    this.misReservas = null;
    this.vistaTarjetasLazo = [];
    this.usuario = new Usuarios();
    this.allInfoTarjetas = [];
  }

  ngOnInit() {
    this.getTarjetasLazo();
  }

  async exitoTarjetaLazoEnviada() {
    const alert = await this.alertController.create({
      header: '¡Genial!',
      message: 'Has reservado tu plaza.<br /><br /> Esta reserva tiene una validez de 10 minutos.',
      buttons: ['OK']
    });
    await alert.present();
  }

  async errorTarjetaLazo() {
    const alert = await this.alertController.create({
      header: '¡Atención!',
      message: 'Ha ocurrido un error. Por favor, inténtalo más tarde.',
      buttons: ['OK']
    });
    await alert.present();
  }

  getTarjetasLazo() {
    this.usuario = new Usuarios();
    let clave = "LISTATARJETA";
    let payload = '{"clave" : "' + clave + '", "id" : "' + this._consulta.usuario.Id + '" }';

    this._consulta.getTarjetasLazo(payload).subscribe(
      result => {
        this.getResevas();
        this.tarjetasLazo = [];
        this.tarjetasLazo = result.value;
        try {
          this.tarjetasLazo.forEach(e => {
            let that = this;
            that.originalTarjetasLazo.push(e);
          })

        } catch (e) {
          console.log(e);
        }
        this.calculo();
      },
      error => {

      }
    )
  }

  calculo() {

    this.vistaTarjetasLazo = this.tarjetasLazo;

    // for(let i = 0; i < this.tarjetasLazo.length; i++){
    //       let numerosLazo : string;
    //       numerosLazo = this.tarjetasLazo[i].identificadorapertura.substr(-4);
    //       this.vistaTarjetasLazo.push(this.tarjetasLazo[i]);
    //       this.vistaTarjetasLazo[i].identificadorapertura = numerosLazo;
    //     }

    if (this.tarjetasLazo.length != 0) {
      this.lazo = true;
      //console.log(this.tarjetasLazo);
    } else {
      this.lazo = false;
    }
  }

  onSelect(id: string, identificadorapertura: string, descripcion: string, estado: string) {
    
      this._consulta.tarjetaLazo = new TarjetasLazo();

      let that = this;

      function encuentroMiLazo(miLazo) {
        if (miLazo.id === id) {
          return that._consulta.tarjetaLazo = miLazo;
        }
        return null;
      }

      this.originalTarjetasLazo.find(encuentroMiLazo);
      //console.log(this._consulta.tarjetaLazo);
    if (estado == "") {
      this.sendLazo();
    }

  }

  sendLazo() {
    let clave = "RESERVA";
    let id_instalacion = "1";

    let payload = '{"clave" : "' + clave + '", "id_instalacion" : "' + id_instalacion + '", "codigo" : "' + this._consulta.tarjetaLazo.identificadorapertura + '" }';

    this._consulta.sendLazoReserva(payload).subscribe(
      result => {
        this.exitoTarjetaLazoEnviada();
        this._router.navigate(['/principal']);
      },
      error => {
        this.errorTarjetaLazo();
      }
    )
  }

  getResevas() {
    let x = '{"clave" : "RESERVAS", "id" : "' + this._consulta.usuario.Id + '" }';
    this._consulta.consultoMisReservas(x).subscribe(
      result => {
        this.misReservas = [];
        this.misReservas = result.value;
        this.comprobarReserva();
      },
      error => {
        console.log(<any>error);
      }
    )
  }

  comprobarReserva() {

  }

}
